import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styled from "@emotion/styled";
import {css} from "@emotion/core";
import Container from "react-bootstrap/Container";

const AppComingSoonSection2Background = styled.div`
  background-color: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.gray_dark};
  font: 20px/28px Roboto;
  @media(max-width:768px) {
    margin-bottom: 50px;
  }
`

const AppComingSoonSection2Title = styled.div`
  font: 58px/62px Abril Fatface;
  margin-bottom:35px;
  color: ${props => props.theme.colors.primary_violet};
  text-align:center;
  @media(max-width:1199px){
    font: 56px/60px Abril Fatface;
  }
  @media(max-width:767px){
    font: 2.5rem/3rem Abril Fatface;
    margin-bottom:17.5px;
  }
`

const TextTitle = styled.div`
  font: 28px/32px Abril Fatface;
  font-weight: bold;
  color: ${props => props.theme.colors.gray_dark};
  margin-bottom: 13px;
  margin-top: 50px;
`

const TextContent = styled.div`
  font: 20px/22px Roboto;
  color: ${props => props.theme.colors.gray_dark};
  @media(max-width: 1199px) {
      margin-bottom: 20px;
  }
`
const ImageColumn = styled(Col)`
  display:flex;
  justify-content:center;
`

export default function AppComingSoonSection2(props) {
  return (
  <AppComingSoonSection2Background backgroundColor={props.Background} color={props.TextColor}>
      <Container>
        <AppComingSoonSection2Title>
            Step by step explanation
        </AppComingSoonSection2Title>
        <Col>
            <Row>
                <Col xs="12" md="12" lg="6">
                    <TextTitle>Answer your career questionnaire</TextTitle>
                    <TextContent>Sign up and answer a set of questions for us to better match you with potential growth partners.</TextContent>
                </Col>
                <ImageColumn xs="12" md="12" lg="6">
                    <img alt="WomenWorks - WomenWorks App Questionnaire" src="/page-app-coming-soon-section2-img1.jpg"
                        css={css`
                            @media(max-width:767px) {
                                display: block;
                                max-width:300px;
                                max-height:150px;
                                width: auto;
                                height: auto;
                            }
                            @media(min-width:768px) {
                                width: 63vw;
                                height: 230px;
                            }
                            @media(min-width:1024px) {
                                width: 47vw;
                                height: 240px;
                            }
                            @media(min-width:1199px) {
                                display: block;
                                max-width:40vw;
                                max-height:350px;
                                width: auto;
                                height: auto;
                            }
                        `}
                    />
                </ImageColumn>
            </Row>
            <Row>
                <Col xs="12" md="12" lg="6">
                    <TextTitle>Get the best matches</TextTitle>
                    <TextContent>We will run your answers through our specialised algorithm and match you with relevant people for your goals.</TextContent>
                </Col>
                <ImageColumn xs="12" md="12" lg="6">
                    <img alt="WomenWorks - WomenWorks App Matches" src="/page-app-coming-soon-section2-img2.jpg"
                        css={css`
                            @media(max-width:767px) {
                                display: block;
                                max-width:300px;
                                max-height:150px;
                                width: auto;
                                height: auto;
                            }
                            @media(min-width:768px) {
                                width: 61vw;
                                height: 240px;
                            }
                            @media(min-width:1024px) {
                                width: 47vw;
                                height: 210px;
                            }
                            @media(min-width:1199px) {
                                display: block;
                                max-width:40vw;
                                max-height:350px;
                                width: auto;
                                height: auto;
                            }
                        `}
                    />
                </ImageColumn>
            </Row>
            <Row>
                <Col xs="12" md="12" lg="6">
                    <TextTitle>Get introduced and connect!</TextTitle>
                    <TextContent>We will connect you and your match over email for a 1 hour 1:1 virtual chat. Enjoy your chat and let us know how it goes!</TextContent>
                </Col>
                <ImageColumn xs="12" md="12" lg="6">
                    <img alt="WomenWorks - WomenWorks App Get Introduced" src="/page-app-coming-soon-section2-img3.jpg"
                        css={css`
                            @media(max-width:767px) {
                                display: block;
                                max-width:300px;
                                max-height:150px;
                                width: auto;
                                height: auto;
                            }
                            @media(min-width:768px) {
                                width: 63vw;
                                height: 240px;
                            }
                            @media(min-width:1024px) {
                                width: 47vw;
                                height: 210px;
                            }
                            @media(min-width:1199px) {
                                display: block;
                                max-width:40vw;
                                max-height:350px;
                                width: auto;
                                height: auto;
                            }
                        `}
                    />
                </ImageColumn>
            </Row>
        </Col>
      </Container>
  </AppComingSoonSection2Background>
  )
}

